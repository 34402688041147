<template>
  <div>
    <div class="flex pd10 flex-between align-center ">
      <div>
        <!-- 0、未发货仅退款 -->
        <div v-if="refund_type == 0 && status == 0">
          <div class="mrb20 size18">未发货仅退款(申请中)</div>
          <div>
            <el-button type="primary" @click="action(productId,1)">同意</el-button>
            <el-button type="warning" @click="action(productId,2)">拒绝</el-button>
          </div>
        </div>
        <div class="mrb20 size18" v-if="refund_type == 0 && status == 1">
          未发货仅退款(已同意)
        </div>
        <div class="mrb20 size18" v-if="refund_type == 0 && status == 2">未发货仅退款(已拒绝)</div>
        <div class="mrb20 size18" v-if="refund_type == 0 &&status == 5">{{ salesData.status_desc }}</div>
        <div class="mrb20 size18" v-if="refund_type == 0 && status == 6">未发货仅退款(用户撤销申请)</div>

        <!-- 1.已发货仅退款 -->
        <div v-if="refund_type == 1 && status == 0">
          <div class="mrb20 size18">已发货仅退款(申请中)</div>
          <div>
            <el-button type="primary" @click="action(productId,1)">同意</el-button>
            <el-button type="warning" @click="action(productId,2)">拒绝</el-button>
          </div>
        </div>

        <div class="mrb20 size18" v-if="refund_type == 1 && status == 1">已发货仅退款(已同意) </div>
        <div class="mrb20 size18" v-if="refund_type == 1 && status == 2">已发货仅退款(已拒绝)</div>
        <div class="mrb20 size18" v-if="refund_type == 1 &&status == 5">{{ salesData.status_desc }}</div>
        <div class="mrb20 size18" v-if="refund_type == 1 && status == 6">已发货仅退款(用户撤销申请)</div>

        <!-- 2.已发货退货退款 -->
        <div v-if="refund_type == 2 && status == 0">
          <div class="mrb20 size18">已发货退货退款(申请中)</div>
          <div>
            <el-button type="primary" @click="action(productId,3)">同意</el-button>
            <el-button type="warning" @click="action(productId,2)">拒绝</el-button>
          </div>
        </div>

        <div class="mrb20 size18" v-if="refund_type == 2 && status == 2">已发货退货退款(已拒绝)</div>
        <div class="mrb20 size18" v-if="refund_type == 2 && status == 3">已发货退货退款(退货同意等待客户寄物流)</div>
        <!--  -->
        <div>
          <div class="mrb20" v-if="refund_type == 2 && status == 4">
            <div class="size18 mrb10">{{ salesData.status_desc }}</div>
            <div class="Grey_color mrb10">买家已退货，收到买家退货时，请验货后同意退款</div>
            <div class="mrb10 flex align-center" v-if="refund_type == 2 && status == 4">
              买家已退货:{{ salesData.refund_logistics_company }}({{ salesData.refund_logistics_no }})<el-button type="text" size="small" @click="material_flow(productId)">查看物流详情</el-button></div>
            <div class="mrb10" v-if="refund_type == 2 && status == 4" style="width: 400px;">
              <!-- 物流信息 -->
              <div v-if="updateTimeStatus !==''">
                {{ updateTimeStatus }}
              </div>
              <div v-if="updateTimeStatus !==''">
                {{ updateTime }}
              </div>
            </div>
            <el-button type="primary" @click="action(productId,5)">确认收到退货？</el-button>
          </div>
          <!--用户已经填写了快递信息  -->
          <div class="pd10">
          </div>
        </div>
        <div class="mrb20 size18" v-if="refund_type == 2 &&status == 5">{{ salesData.status_desc }}</div>
        <div class="mrb20 size18" v-if="refund_type == 2 && status == 6">已发货退货退款(用户撤销申请)</div>

        <!-- 3.已发货换货 -->
        <div v-if="refund_type == 3 && status == 0">
          <div class="mrb20 size18">已发货换货(申请中)</div>
          <div>
            <el-button type="primary" @click="action(productId,1)">同意</el-button>
            <el-button type="warning" @click="action(productId,2)">拒绝</el-button>
          </div>
        </div>

        <div class="mrb20 size18" v-if="refund_type == 3 && status == 2">已发货换货(已拒绝)</div>
        <div class="mrb20 size18" v-if="refund_type == 3 && status == 3">已发货换货(退货同意等待客户寄物流)</div>
        <div class="mrb20 size18" v-if="refund_type == 3 && status == 4">
          <div>已发货换货(客户已寄等待签收)</div>
          <div> <el-button type="primary" @click="action(productId,5)">已签收退款完成</el-button></div>
        </div>
        <div class="mrb20 size18" v-if="refund_type == 3 &&status == 5">{{ salesData.status_desc }}</div>
        <div class="mrb20 size18" v-if="refund_type == 3 && status == 6">已发货换货(用户撤销申请)</div>

        <!-- 退货退款 -->
        <div v-if="refund_type == 4 && status == 0">
          <div class="mrb20 size18">退货退款(申请中)</div>
          <div>
            <el-button type="primary" @click="action(productId,1)">同意</el-button>
            <el-button type="warning" @click="action(productId,2)">拒绝</el-button>
          </div>
        </div>

        <div class="mrb20 size18" v-if="refund_type == 4 && status == 2">退货退款(已拒绝)</div>
        <div class="mrb20 size18" v-if="refund_type == 4 && status == 3">退货退款(退货同意等待客户寄物流)</div>
        <div class="mrb20 size18" v-if="refund_type == 4 && status == 4">退货退款(客户已寄等待签收)</div>
        <div class="mrb20 size18" v-if="refund_type == 4 && status == 5">退货退款(已签收退款完成)</div>
        <div class="mrb20 size18" v-if="refund_type == 4 && status == 6">退货退款(用户撤销申请)</div>

        <div v-if=" salesData.order_refund_log">
          <div class="mrb10 Grey_color">退款成功时间：{{ salesData.order_refund_log.created_at }}</div>
          <div class="mrb10">退款金额:￥{{ salesData.order_refund_log.refund_fee }}</div>
        </div>
      </div>

      <div class="size18">
        退款流程:1、卖家申请退货退款 2、卖家处理退货申请 3、卖家退货 4、退款完毕
      </div>
    </div>

    <!-- 退款详情 -->
    <div class=" pd10 bg-white mrt20">
      退款详情
    </div>

    <div class="mrt20 flex flex-between ">
      <div style="width: 33%;">
        <div class="size18 mrb10">交易信息</div>
        <div class="size16 flex align-center">买家：<div v-if="salesData.order">{{ salesData.order.consignee }}</div>
        </div>
        <div class="size16 flex align-center">订单编号:
          <div v-if="salesData.order">{{ salesData.order.order_sn }} <el-button @click="copyClick(salesData.order.order_sn)" type="text">复制</el-button>
          </div>
        </div>
        <div class="mrb5  size16 flex align-center">成交时间：<div v-if="salesData.order">{{ salesData.order.pay_time }}</div>
        </div>
        <div class="mrb5 flex size16 align-center">邮费：￥<div v-if="salesData.order">{{ salesData.order.delivery }}</div>
        </div>
        <div class="mrb5  size16 flex align-center">商品总价：<div v-if="salesData.order">￥{{ salesData.order.total_amount }}</div>
        </div>
      </div>
      <div style="width: 33%;">
        <div class="size18 mrb10">退款信息</div>
        <div class=" flex size16 align-center">退款编号：{{ salesData.refund_sn }}<el-button @click="copyClick(salesData.refund_sn)" type="text">复制</el-button></div>
        <div class="mrb5 size16 flex align-center">退款金额：￥{{ salesData.amount }}</div>
        <div class="mrb5 size16 flex align-center">原因：{{ salesData.reason_desc }}</div>
        <div class="mrb5 size16 flex align-center">要求：{{  demand }}</div>
        <div class="mrb5 size16 flex align-center">说明：{{   salesData.describe}}</div>
        <div class="mrb5 size16 flex align-start">
          <div class="flex white-space">
            凭证：
          </div>
          <div class=" flex flex1 flex-wrap ">
            <div class=" mrr10 " v-for=" (item,index) in salesData.images" :key="index">
              <el-image :preview-src-list="[item]" style="width: 80px; height: 80px" :src="item" fit="fill"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="size18 mrb10">商品信息</div>
        <div class="" v-for=" (item,index) in salesData.order_goods" :key="index">
          <div class="flex size16">
            <div class="mrr5" v-if="item.cover">
              <el-image style="width: 100px; height: 100px" :src="item.cover" fit="fill"></el-image>
            </div>
            <div>
              <div class="mrb5 shop_nameClass" v-if="item.goods_name">{{ item.goods_name }}</div>
              <div class="mrb5">
                单价： ￥{{ item.price }}
              </div>
              <div>
                数量：{{ item.goods_num }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="mrt20 pd10 bg-white item_box">
      <div class="size18 mrb20">协商历史</div>
      <div>
        <div class="flex mrb20" v-for=" (item,index) in salesData.refund_log">
          <div class="mrr10">
            <el-image v-if="item.user_type == 1" style="width: 50px; height: 50px" :src="salesData.user_logo" fit="fill"></el-image>
            <el-image v-if="item.user_type == 2" style="width: 50px; height: 50px" :src="salesData.shop_logo" fit="fill"></el-image>
          </div>
          <div>
            <div class="mrb5 size18">{{ item.user_type_desc }}</div>
            <div class="mrb5 size18">{{ item.title }}</div>
            <div class="mrb5">{{ item.created_at }}</div>
            <div class="mrb5">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 退款原因 -->
    <el-dialog title="提示" :visible.sync="refundVisible" width="30%">
      <div class="mrb20 size16">请输入拒绝退款原因</div>
      <div><el-input v-model="content" placeholder="请输入" style="width: 100%;"></el-input></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundVisible = false">取 消</el-button>
        <el-button type="primary" @click="refund_reason">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      refundVisible: false,
      content: "",
      salesData: {},
      refund_type: "", //状态
      status: "",
      productId: "",
      demand: "",
      refundID: "",
      updateTime: "",
      updateTimeStatus: "",
    };
  },

  created() {},
  mounted() {
    this.getList();
  },
  computed: {},

  methods: {
    material_flow(id) {
      this.$router.push({
        path: "/order/refundLogistics?id=" + id,
      });
    },
    action(id, type) {
      let text = "";
      if (type == 1 || type == 3) {
        text = "同意";
        this.$confirm("是否" + text, "提示", {
          confirmButtonText: "确定" + text,
          cancelButtonText: "取消操作",
          type: "warning",
        })
          .then(() => {
            this.refund_action(id, type);
          })
          .catch(() => {});
      }
      if (type == 2) {
        text = "拒绝";
        this.refundVisible = true;
        this.refundID = id;
      }

      if (type == 5) {
        text = "已签收退款完成";
        this.$confirm("是否" + text, "提示", {
          confirmButtonText: "确定" + text,
          cancelButtonText: "取消操作",
          type: "warning",
        })
          .then(() => {
            this.refund_action(id, type);
          })
          .catch(() => {});
      }
    },
    // 退款请求
    async refund_action(id, type, content) {
      const { code, msg } = await this.$Api.refundOrderAction({
        id: id,
        type: type,
        content: content,
      });

      if (code == 200) {
        this.$message.success(msg);
      } else {
        this.$message.error(msg);
      }
      this.getList(this.pageInfo);
    },
    async getList() {
      const { data } = await this.$Api.orderRefundOrderDetail({
        order_id: this.$route.query.order_id,
        goods_id: this.$route.query.goods_id,
      });
      this.salesData = data;
      this.refund_type = data.refund_type;
      this.productId = data.id;
      this.demand = data.refund_log[0].content;
      this.status = data.status;
      // if (data.logistics) {
      //   if (!data.logistics.result.list) return;
      //   let time = data.logistics.result.list[0];
      //   // console.log("tiem6666666", time);
      //   this.updateTimeStatus = time.status;
      //   this.updateTime = time.time;
      // }
      if (data.logistics.result) {
        if (!data.ligistics.result.list.length) return;
        let time = data.logistics.result.list[0];
        // console.log("tiem6666666", time);
        this.updateTimeStatus = time.status;
        this.updateTime = time.time;
      }

      // console.log("售后详情", data);
    },
    copyClick(context) {
      navigator.clipboard
        .writeText(context)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch((err) => {
          this.$message.error("复制失败");
        });
    },
    // 退款原因
    refund_reason() {
      const content = this.content;
      if (content == "") {
        this.$message.error("请输入拒绝原因");
        return;
      }
      this.refund_action(this.refundID, 2, content);
      this.refundVisible = false;
    },
  },
};
</script>
<style lang='scss' scoped>
.item_box {
  ::v-deep .el-image {
    border-radius: 50%;
  }
}
.shop_nameClass {
  width: 300px;
  overflow-wrap: break-word;
}
</style>